import { configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import appUserReducer from './core/slices/UserSlice';
import featureFlagReducer from './core/slices/FeatureFlagSlice';
import optionsReducer from './core/slices/OptionsSlice';
import filterReducer from './core/slices/FilterSlice';
import propertiesDrawerReducer from './core/slices/PropertiesDrawerSlice';
import configureDrawerReducer from './core/slices/ConfigureDrawerSlice';
import proxyReducer from './core/slices/ProxySlice';
import previewReducer from './core/slices/PreviewSlice';
import selectedReducer from './core/slices/SelectedSlice';
import errorReducer from './core/slices/ErrorSlice';
import userFeedBackReducer from './core/slices/UserFeedBackSlice';
import editAssetReducer from './assets/components/EditAssets/EditAssetsSlice';
import linkToFormReducer from './assets/components/EditAssets/RequiredDataForm/LinkToForm/LinkToFormSlice';
import requiredDataFormReducer from './assets/components/EditAssets/RequiredDataForm/RequiredDataFormSlice';
import secondaryDataFormReducer from './assets/components/EditAssets/SecondaryDataForm/SecondaryDataFormSlice';
import createOrderReducer from './assets/components/CreateOrders/CreateOrdersSlice';

const rootReducer = combineReducers({
  appUser: appUserReducer,
  options: optionsReducer,
  filter: filterReducer,
  featureFlags: featureFlagReducer,
  propertiesDrawer: propertiesDrawerReducer,
  configureDrawer: configureDrawerReducer,
  proxy: proxyReducer,
  preview: previewReducer,
  selected: selectedReducer,
  editAsset: editAssetReducer,
  linkToForm: linkToFormReducer,
  requiredDataForm: requiredDataFormReducer,
  secondaryDataForm: secondaryDataFormReducer,
  error: errorReducer,
  userFeedBack: userFeedBackReducer,
  createOrder: createOrderReducer,
});

const logger:any = createLogger({
  collapsed: true,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => {
    const defaults = getDefaultMiddleware();
    if (window.location.origin.includes('localhost:4200')) {
      return defaults.concat(logger);
    }
    return defaults;
  },
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppGetState = AppStore['getState'];
export type AppDispatch = AppStore['dispatch'];
