const ASSETS_FILTER_CATEGORIES = {
  ADMINISTRATIVE: 'Administrative',
  DATES: 'Dates',
  DESCRIPTIVE: 'Descriptive',
  IDS: 'IDs',
  PROM_DATA: 'ProM_Data',
  STATUS: 'Status',
  TECHNICAL: 'Technical',
  USERS: 'Users',
  CONTENT: 'Content',
  AIRTABLE: 'Airtable',
};

const DEFAULT_ASSET_COLUMNS = [
  {
    displayName: 'Preview',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'imageURL',
    minWidth: 110,
    autoSize: true,
  },
  {
    displayName: 'GTM Title',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'title',
    minWidth: 85,
  },
  {
    displayName: 'Title',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.fileTitle',
    minWidth: 115,
  },
  {
    displayName: 'Storage System',
    group: ASSETS_FILTER_CATEGORIES.ADMINISTRATIVE,
    key: 'storageSystem',
    minWidth: 165,
    autoSize: true,
  },
  {
    displayName: 'Mediator Progress',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'mediatorProgress',
    minWidth: 185,
    autoSize: true,
  },
  // NOTE: This column is not necessary and doesn't communicate
  // anything of use to the user but, keeping this code until we
  // see that it doesn't effect anything in Prod...
  // {
  //   displayName: 'Keyframe Ready',
  //   group: ASSETS_FILTER_CATEGORIES.STATUS,
  //   key: 'mamAsset.keyframeReady',
  //   minWidth: 175,
  // },
  {
    displayName: 'Duration',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'duration',
    minWidth: 115,
    autoSize: true,
  },
  {
    displayName: 'Episode Title',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'episodeTitle',
    minWidth: 145,
  },
  {
    displayName: 'Feature Title',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'featureTitle',
    minWidth: 145,
  },
  {
    displayName: 'Material Id',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'materialId',
    minWidth: 130,
  },
  {
    displayName: 'Series Title',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'seriesTitle',
    minWidth: 135,
  },
  {
    displayName: 'Production Number',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'productionNumber',
    minWidth: 190,
    autoSize: true,
  },
  {
    displayName: 'Text Version',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'textVersion',
    minWidth: 140,
    autoSize: true,
  },
  {
    displayName: 'GTM ID',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'gtmId',
    minWidth: 85,
    order: 13,
    autoSize: true,
  },
  {
    displayName: 'GTM Unified Version ID',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'gtmVersionUnifiedId',
    minWidth: 85,
    order: 14,
  },
  {
    displayName: 'TMS ID',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'tmsId',
    minWidth: 85,
    order: 15,
    autoSize: true,
  },
  {
    displayName: 'MDV ID',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'gtmMdvUnifiedId',
    minWidth: 85,
    order: 16,
    autoSize: true,
  },
  {
    displayName: 'Season Title',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'seasonTitle',
    minWidth: 140,
    order: 17,
  },
  {
    displayName: 'Episode Number',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'episodeNo',
    minWidth: 85,
    order: 18,
    autoSize: true,
  },
  {
    displayName: 'Original Air Network',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'originalAirNetwork',
    minWidth: 200,
    order: 19,
    autoSize: true,
  },
].map((item, index) => ({ ...item, order: index + 1 }));

const ALL_ASSET_COLUMNS = [
  ...DEFAULT_ASSET_COLUMNS,
  {
    displayName: 'Aspect Ratio',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'aspectRatio',
    minWidth: 145,
    autoSize: true,
  },
  {
    displayName: 'File Name',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.fileName',
    minWidth: 125,
  },
  {
    displayName: 'ID',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'id',
    minWidth: 70,
    autoSize: true,
  },
  {
    displayName: 'Reference ID',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'pmam.referenceId',
    minWidth: 130,
    autoSize: true,
  },
  {
    displayName: 'Merlin Delivery Status',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.merlinDeliveryStatus',
    minWidth: 130,
  },
  {
    displayName: 'Total Run Time',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'totalRunTime',
    minWidth: 160,
    autoSize: true,
  },
  {
    displayName: 'Source System',
    group: ASSETS_FILTER_CATEGORIES.ADMINISTRATIVE,
    key: 'sourceSystem',
    minWidth: 160,
    autoSize: true,
  },
  {
    displayName: 'Copyright Owner',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'pmam.copyrightOwner',
    minWidth: 85,
  },
  {
    displayName: 'Season Number',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'seasonNo',
    minWidth: 85,
    autoSize: true,
  },
  {
    displayName: 'Rating',
    group: ASSETS_FILTER_CATEGORIES.CONTENT,
    key: 'rating',
    minWidth: 100,
    autoSize: true,
  },
  {
    displayName: 'Original Air Date',
    group: ASSETS_FILTER_CATEGORIES.DATES,
    key: 'originalAirDate',
    minWidth: 170,
    autoSize: true,
  },
  {
    displayName: 'Proxy Available',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'proxyAvailable',
    minWidth: 160,
    autoSize: true,
  },
  {
    displayName: 'Mediator Progress Percentage',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'mediatorProgressPercentage',
    minWidth: 270,
    autoSize: true,
  },
  {
    displayName: 'Material Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'materialType',
    minWidth: 150,
    autoSize: true,
  },
  {
    displayName: 'Frame Rate',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'frameRate',
    minWidth: 135,
  },
  {
    displayName: 'Asset Id',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'pmam.assetId',
    minWidth: 130,
    autoSize: true,
  },
  {
    displayName: 'File Size',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.fileSize',
    minWidth: 115,
    autoSize: true,
  },
  {
    displayName: 'Created By',
    group: ASSETS_FILTER_CATEGORIES.USERS,
    key: 'pmam.createdBy',
    minWidth: 130,
  },
  {
    displayName: 'Asset Created At',
    group: ASSETS_FILTER_CATEGORIES.DATES,
    key: 'assetCreatedAt',
    minWidth: 130,
    autoSize: true,
  },
  {
    displayName: 'Brand',
    group: ASSETS_FILTER_CATEGORIES.USERS,
    key: 'brand',
    minWidth: 100,
  },
  {
    displayName: 'House Number',
    group: ASSETS_FILTER_CATEGORIES.ADMINISTRATIVE,
    key: 'pmam.houseNumber',
    minWidth: 160,
  },
  {
    displayName: 'Marked For Delete',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.markedForDelete',
    minWidth: 180,
    autoSize: true,
  },
  {
    displayName: 'Color Space',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.colorSpace',
    minWidth: 140,
    autoSize: true,
  },
  {
    displayName: 'Upload Date',
    group: ASSETS_FILTER_CATEGORIES.DATES,
    key: 'pmam.uploadDate',
    minWidth: 140,
    autoSize: true,
  },
  {
    displayName: 'Upload Progress Name',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.uploadProgressName',
    minWidth: 215,
    autoSize: true,
  },
  {
    displayName: 'Airtable Status',
    group: ASSETS_FILTER_CATEGORIES.AIRTABLE,
    key: 'pmam.airtableStatus',
    minWidth: 160,
    autoSize: true,
  },
  {
    displayName: 'Airtable Project',
    group: ASSETS_FILTER_CATEGORIES.AIRTABLE,
    key: 'pmam.project',
    minWidth: 160,
    autoSize: true,
  },
  {
    displayName: 'Premier Date',
    group: ASSETS_FILTER_CATEGORIES.DATES,
    key: 'pmam.premierDate',
    minWidth: 150,
    autoSize: true,
  },
  {
    displayName: 'Upload Id',
    group: ASSETS_FILTER_CATEGORIES.IDS,
    key: 'pmam.uploadId',
    minWidth: 120,
  },
  {
    displayName: 'Updated By',
    group: ASSETS_FILTER_CATEGORIES.USERS,
    key: 'pmam.updatedBy',
    minWidth: 140,
  },
  {
    displayName: 'Content Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'pmam.contentType',
    minWidth: 150,
    autoSize: true,
  },
  {
    displayName: 'Media Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'mediaType',
    minWidth: 135,
    autoSize: true,
  },
  {
    displayName: 'Shoot Date',
    group: ASSETS_FILTER_CATEGORIES.DATES,
    key: 'pmam.shootDate',
    minWidth: 135,
    autoSize: true,
  },
  {
    displayName: 'Prom Due Date',
    group: ASSETS_FILTER_CATEGORIES.DATES,
    key: 'pmam.promDueDate',
    minWidth: 160,
    autoSize: true,
  },
  {
    displayName: 'Master Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'pmam.masterType',
    minWidth: 140,
  },
  {
    displayName: 'Physical Storage',
    group: ASSETS_FILTER_CATEGORIES.ADMINISTRATIVE,
    key: 'pmam.physical',
    minWidth: 115,
    autoSize: true,
  },
  {
    displayName: 'Video Bit Rate',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.videoBitRate',
    minWidth: 155,
  },
  {
    displayName: 'Airtable Id',
    group: ASSETS_FILTER_CATEGORIES.AIRTABLE,
    key: 'pmam.airtableId',
    minWidth: 125,
  },
  {
    displayName: 'Airtable Due Date',
    group: ASSETS_FILTER_CATEGORIES.AIRTABLE,
    key: 'pmam.airtableDueDate',
    minWidth: 180,
  },
  {
    displayName: 'Audio Bit Rate',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.audioBitRate',
    minWidth: 155,
    autoSize: true,
  },
  {
    displayName: 'Prom Deliverable Number',
    group: ASSETS_FILTER_CATEGORIES.PROM_DATA,
    key: 'pmam.promDeliverableNumber',
    minWidth: 235,
    autoSize: true,
  },
  {
    displayName: 'Prom Promo Code',
    group: ASSETS_FILTER_CATEGORIES.PROM_DATA,
    key: 'pmam.promPromoCode',
    minWidth: 180,
    autoSize: true,
  },
  {
    displayName: 'Acquisition Source',
    group: ASSETS_FILTER_CATEGORIES.ADMINISTRATIVE,
    key: 'pmam.acquisitionSource',
    minWidth: 190,
    autoSize: true,
  },
  {
    displayName: 'Replacement In Progress',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.replacementInProgress',
    minWidth: 235,
    autoSize: true,
  },
  {
    displayName: 'Marked For Digitization',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.markedForDigitization',
    minWidth: 220,
    autoSize: true,
  },
  {
    displayName: 'Mlai Status',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.mlaiStatus',
    minWidth: 130,
    autoSize: true,
  },
  {
    displayName: 'Mask Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'pmam.maskType',
    minWidth: 130,
    autoSize: true,
  },
  {
    displayName: 'Production Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'productionType',
    minWidth: 130,
  },
  {
    displayName: 'Audio Bit Depth',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.audioBitDepth',
    minWidth: 165,
  },
  {
    displayName: 'Sampling Rate',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.samplingRate',
    minWidth: 155,
    autoSize: true,
  },
  {
    displayName: 'Parent Path',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.parentPath',
    minWidth: 135,
  },
  {
    displayName: 'Language',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.language',
    minWidth: 125,
    autoSize: true,
  },
  {
    displayName: 'Primary Language',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'primaryLanguage',
    minWidth: 125,
    autoSize: true,
  },
  {
    displayName: 'Wrapper',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.wrapper',
    minWidth: 115,
  },
  {
    displayName: 'File Extension',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.fileExtension',
    minWidth: 150,
    autoSize: true,
  },

  {
    displayName: 'Video Scan Type',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.videoScanType',
    minWidth: 170,
    autoSize: true,
  },
  {
    displayName: 'Asset Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'pmam.assetType',
    minWidth: 130,
  },
  {
    displayName: 'Version Type',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'pmam.versionType',
    minWidth: 145,
  },
  {
    displayName: 'Video Bit Depth',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.videoBitDepth',
    minWidth: 165,
    autoSize: true,
  },
  {
    displayName: 'Audio Profile',
    group: ASSETS_FILTER_CATEGORIES.TECHNICAL,
    key: 'pmam.audioProfile',
    minWidth: 145,
  },
  {
    displayName: 'Version Name',
    group: ASSETS_FILTER_CATEGORIES.DESCRIPTIVE,
    key: 'versionName',
    minWidth: 150,
  },
  {
    displayName: 'Deactivated',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.deactivated',
    minWidth: 135,
    autoSize: true,
  },
  {
    displayName: 'Approval Status',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.svApprovalStatus',
    minWidth: 200,
    order: 20,
    autoSize: true,
  },
  {
    displayName: 'Restricted Status',
    group: ASSETS_FILTER_CATEGORIES.STATUS,
    key: 'pmam.restrictedStatus',
    minWidth: 200,
    autoSize: true,
  },
];

export { DEFAULT_ASSET_COLUMNS, ALL_ASSET_COLUMNS };
